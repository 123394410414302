import axios from 'axios';

const unsubscribeUser = async (email) => {
  try {
    console.log('Enviando solicitud de desubscripción para:', email);
    const response = await axios.post('https://api21.com.do/api/v1/subscribe-client', {
      email,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error en la desubscripción:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export default unsubscribeUser;
