/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,

  Hidden,
} from '@material-ui/core/';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import {
  differenceInHours,
  format,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoIcon from '@material-ui/icons/Info';

import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import timeRegex from '../../utils/timeRegex';

import NumberIncrements from '../NumberIncrements';

import './RestaurantSchedule.scss';
import * as CatalogsApi from '../../api/catalogs';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';

const RestaurantSchedule = ({ setReload }) => {
  const { scheduleData } = useContext(RestaurantContext);

  const [statusHorarios, setStatusHorarios] = useState([]);

  const [horariosDomingo, setHorariosDomingo] = useState([]);
  const [horariosLunes, setHorariosLunes] = useState([]);
  const [horariosMartes, setHorariosMartes] = useState([]);
  const [horariosMiercoles, setHorariosMiercoles] = useState([]);
  const [horariosJueves, setHorariosJueves] = useState([]);
  const [horariosViernes, setHorariosViernes] = useState([]);
  const [horariosSabado, setHorariosSabado] = useState([]);

  const [posDomingo, setPosDomingo] = useState(0);
  const [posLunes, setPosLunes] = useState(0);
  const [posMartes, setPosMartes] = useState(0);
  const [posMiercoles, setPosMiercoles] = useState(0);
  const [posJueves, setPosJueves] = useState(0);
  const [posViernes, setPosViernes] = useState(0);
  const [posSabado, setPosSabado] = useState([]);

  const [isFieldActive, setIsFieldActive] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [hasEdit, setHasEdit] = useState(false);
  const [reload, setLocalReload] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [automaticReservations, setAutomaticReservations] = useState([]);

  const [error, setError] = useState(null);

  const borrarHorario = (dia) => {
    let array = [];
    if(dia == "Domingo"){
      array = horariosDomingo.filter((horario) => horario.posicion != posDomingo)
      setHorariosDomingo([...array]);
      setPosDomingo(posDomingo - 1);
    }
    if(dia == "Lunes"){
      array = horariosLunes.filter((horario) => horario.posicion != posLunes)
      setHorariosLunes([...array]);
      setPosLunes(posLunes - 1);
    }
    if(dia == "Martes"){
      array = horariosMartes.filter((horario) => horario.posicion != posMartes)
      setHorariosMartes([...array]);
      setPosMartes(posMartes - 1);
    }
    if(dia == "Miércoles"){
      array = horariosMiercoles.filter((horario) => horario.posicion != posMiercoles)
      setHorariosMiercoles([...array]);
      setPosMiercoles(posMiercoles - 1);
    }
    if(dia == "Jueves"){
      array = horariosJueves.filter((horario) => horario.posicion != posJueves)
      setHorariosJueves([...array]);
      setPosJueves(posJueves - 1);
    }
    if(dia == "Viernes"){
      array = horariosViernes.filter((horario) => horario.posicion != posViernes)
      setHorariosViernes([...array]);
      setPosViernes(posViernes - 1);
    }
    if(dia == "Sábado"){
      array = horariosSabado.filter((horario) => horario.posicion != posSabado)
      setHorariosSabado([...array]);
      setPosSabado(posSabado - 1);
    }
  }
  
  const toggleContent = (dia) => {

    const nuevoHorario = {
      dia: dia,
      opening: "00:00:00",
      closing: "00:00:00",
      posicion: 0,
      capacity: 0,
    }

    let array = [];

    if(dia == "Domingo"){
      nuevoHorario.posicion = posDomingo + 1;
      setPosDomingo(posDomingo + 1);
      array = [...horariosDomingo];
      array.push(nuevoHorario);
      setHorariosDomingo([...array]);
    }
    if(dia == "Lunes"){
      nuevoHorario.posicion = posLunes + 1;
      setPosLunes(posLunes + 1);
      array = [...horariosLunes];
      array.push(nuevoHorario);
      setHorariosLunes([...array]);
    }
    if(dia == "Martes"){
      nuevoHorario.posicion = posMartes + 1;
      setPosMartes(posMartes + 1);
      array = [...horariosMartes];
      array.push(nuevoHorario);
      setHorariosMartes([...array]);
    }
    if(dia == "Miércoles"){
      nuevoHorario.posicion = posMiercoles + 1;
      setPosMiercoles(posMiercoles + 1);
      array = [...horariosMiercoles];
      array.push(nuevoHorario);
      setHorariosMiercoles([...array]);
    }
    if(dia == "Jueves"){
      nuevoHorario.posicion = posJueves + 1;
      setPosJueves(posJueves + 1);
      array = [...horariosJueves];
      array.push(nuevoHorario);
      setHorariosJueves([...array]);
    }
    if(dia == "Viernes"){
      nuevoHorario.posicion = posViernes + 1;
      setPosViernes(posViernes + 1);
      array = [...horariosViernes];
      array.push(nuevoHorario);
      setHorariosViernes([...array]);
    }
    if(dia == "Sábado"){
      nuevoHorario.posicion = posSabado + 1;
      setPosSabado(posSabado + 1);
      array = [...horariosSabado];
      array.push(nuevoHorario);
      setHorariosSabado([...array]);
    }

    console.log('====================================');
    console.log(array);
    console.log('====================================');
    
    setHasEdit(true);
  };
  
  const fetchAutomaticReservations = async () => {
    try {
      const response = await RestaurantsApi.getDayhoursAuto();

      let statusDeDias = [];

      statusDeDias = response.data.filter((horario) => horario.item == -1);

      if(statusDeDias.length == 0){
        statusDeDias = [
            {
              "day": {
                "id": "eyJpdiI6ImJFUmZKTEpsQ0FxTUpsU29cL3BqTmpRPT0iLCJ2YWx1ZSI6IjFwTzZcLzB3dmVVUzk0VnZcL0E0c2tVZz09IiwibWFjIjoiMzM4NWE5ODNmN2U4YmI5OGNjMTczMzI5YzI1ZTkxNTIyZTEyNTQyNzM1NWU0Y2I0YTRiZjMzMzIzYWNhOWFkYSJ9",
                "name": "Domingo"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6ImZoRnpUNDVhNlpsakZsUDBEODVUUWc9PSIsInZhbHVlIjoiTklXeHBObHF3Z3JzSUZTaXM2Qm9OUT09IiwibWFjIjoiNmIzNjhmYTVjZjJmZTgyMGNkNDRjNjU1ZDk0Y2Q3NzY0Y2I3NmI5NzI0NWMwYjI4MjI3ZWJhMmE3YTNlZTdkOCJ9",
                "name": "Lunes"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6IlA0cGVqRWdWNllYWlNCZG9CK0w2aGc9PSIsInZhbHVlIjoiWFBHSVpzVGdcL1huVVlUXC9xYjV4cWx3PT0iLCJtYWMiOiI1MTU2YWUzYTBkZjBjZTk2MmFjMDIxMTQ1ZDZlNTU1MDI5OGZmYWJkMzU4ZDljYzRjNjM3Y2NmYmQ1ZmM0ZDRjIn0=",
                "name": "Martes"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6InMxWHpRc3g1OFJsTXZhbkJnSm1DOGc9PSIsInZhbHVlIjoieUFmamN3YzFNOHVSWmlcL2FjM1ExYlE9PSIsIm1hYyI6IjhjMTllYzNkMTU4YzdhYWQ3YmU1ZjIxMDgwNWQ2ZTVkOGIzNWMwMDVlYjA2YzQ5ZTRlODhkYzQ4NzNmMzk1ZGQifQ==",
                "name": "Miércoles"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6Ik5hNGlNU1ZCb0l0YWJaZVFXZVh6SHc9PSIsInZhbHVlIjoiZ0tEcUU1T3FcL0ZXMzA3cDRielFJWXc9PSIsIm1hYyI6IjZhNmM1YzVkNmRhMzJmNzBmNDgyNzExMjg0ODdkN2MwOTllZmZiNjYxMzQ2ZDMxZWI1NjQ1YTY4MzA4NDg1NDUifQ==",
                "name": "Jueves"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6Img3SVRNREFlZEIrMHRKNE0yWTB4a3c9PSIsInZhbHVlIjoiNURBZ0NvWEpWOHIrRHBcL25KQkZ5bFE9PSIsIm1hYyI6ImQwZWJiOTY0MmMxYzgzY2U1YTk5MGQzMTIxNWQ0NzkzYmRhYTFmZDZjMGNjZjgwMmQ3NWQwMzY1Y2EzMTNjZTYifQ==",
                "name": "Viernes"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            },
            {
              "day": {
                "id": "eyJpdiI6Ik80eGVEZjFEU1YrSlVZXC9MM3laQ09BPT0iLCJ2YWx1ZSI6IlwvNDlcL29QaWtvOE9qUlJoM21wblc1QT09IiwibWFjIjoiZGY5MTBmOTQxOGE5NmJkNWE1NmE1NTM4YTA1Mjc2NGQzNDFhN2UzZmUzNzNmNzg5ZWE3MjVkM2Q5MjMyODMzNiJ9",
                "name": "Sábado"
              },
              "opening": "00:00:00",
              "closing": "00:00:00",
              "item": -1,
              "restaurantId": -1,
              "status": 1,
              "createdAdd": null,
              "automaticHours": null,
              "automaticReservations": 0,
              "hoursDay": null,
              "uniqueItemCount": 0
            }
          ]
      }

      console.log("status ", statusDeDias);
      

      let horariosEnBruto = response.data.filter((horario) => horario.item != -1);

      setStatusHorarios(statusDeDias.map((horario) => {
        return(
          {
            dia: horario.day.name,
            opening: horario.opening,
            closing: horario.closing,
            posicion: horario.item,
            status: horario.status,
          }
        );
      }));

      let domingo = [];
      let lunes = [];
      let martes = [];
      let miercoles = [];
      let jueves = [];
      let viernes = [];
      let sabado = [];

      let contadorDomingo = 0;
      let contadorLunes = 0;
      let contadorMartes = 0;
      let contadorMiercoles = 0;
      let contadorJueves = 0;
      let contadorViernes = 0;
      let contadorSabado = 0;
      
      horariosEnBruto.forEach((horario) => {
        if(horario.day.name == "Domingo"){
          if(contadorDomingo < horario.item){
            contadorDomingo = horario.item;
          }
          domingo.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Lunes"){
          if(contadorLunes < horario.item){
            contadorLunes = horario.item;
          }
          lunes.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Martes"){
          if(contadorMartes < horario.item){
            contadorMartes = horario.item;
          }
          martes.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Miércoles"){
          if(contadorMiercoles < horario.item){
            contadorMiercoles = horario.item;
          }
          miercoles.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Jueves"){
          if(contadorJueves < horario.item){
            contadorJueves = horario.item;
          }
          jueves.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Viernes"){
          if(contadorViernes < horario.item){
            contadorViernes = horario.item;
          }
          viernes.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }

        if(horario.day.name == "Sábado"){
          if(contadorSabado < horario.item){
            contadorSabado = horario.item;
          }
          sabado.push(
            {
              dia: horario.day.name,
              opening: horario.opening,
              closing: horario.closing,
              posicion: horario.item,
              capacity: horario.automaticReservations,
            }
          )
        }
        
        
      })

      setHorariosDomingo([...domingo]);
      setHorariosLunes([...lunes]);
      setHorariosMartes([...martes]);
      setHorariosMiercoles([...miercoles]);
      setHorariosJueves([...jueves]);
      setHorariosViernes([...viernes]);
      setHorariosSabado([...sabado]);

      setPosDomingo(contadorDomingo);
      setPosLunes(contadorLunes);
      setPosMartes(contadorMartes);
      setPosMiercoles(contadorMiercoles);
      setPosJueves(contadorJueves);
      setPosViernes(contadorViernes);
      setPosSabado(contadorSabado);

      console.log('Horarios en bruto:', horariosEnBruto);
      setIsLoading(false);
    } catch (err) {
      console.error('Error al obtener reservas automáticas:', err);
      setError(err.message);
      return {}; // Devuelve un objeto vacío en caso de error
    }
  };

  useEffect(() => {
    fetchAutomaticReservations(); // Esta llamada se puede mover al final de la función

  }, [scheduleData, reload]);

  const changeCheckbox = (dia) => {
    let array = statusHorarios.map((item) => {
      if(item.dia == dia){
        if(item.status == 0){
          item.status = 1
        }else{
          item.status = 0
        }
      }
      return item
    });

    setStatusHorarios(array);

  };

  const manejadorDeHorarios = (type, value, day, pos) => {

    let array = [];

    // Cambiar horario de apertura
    if(type == 1){
      if(day == "Domingo"){
        array = horariosDomingo.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosDomingo([...array]);
      }
      if(day == "Lunes"){
        array = horariosLunes.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosLunes([...array]);
      }
      if(day == "Martes"){
        array = horariosMartes.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosMartes([...array]);
      }
      if(day == "Miércoles"){
        array = horariosMiercoles.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosMiercoles([...array]);
      }
      if(day == "Jueves"){
        array = horariosJueves.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosJueves([...array]);
      }
      if(day == "Viernes"){
        array = horariosViernes.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosViernes([...array]);
      }
      if(day == "Sábado"){
        array = horariosSabado.map((horario) => {
          if(horario.posicion == pos){
            horario.opening = value;
          }
          return horario;
        });

        setHorariosSabado([...array]);
      }
    }

    // Cambiar horario de cierre
    if(type == 2){
      if(day == "Domingo"){
        array = horariosDomingo.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosDomingo([...array]);
      }
      if(day == "Lunes"){
        array = horariosLunes.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosLunes([...array]);
      }
      if(day == "Martes"){
        array = horariosMartes.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosMartes([...array]);
      }
      if(day == "Miércoles"){
        array = horariosMiercoles.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosMiercoles([...array]);
      }
      if(day == "Jueves"){
        array = horariosJueves.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosJueves([...array]);
      }
      if(day == "Viernes"){
        array = horariosViernes.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosViernes([...array]);
      }
      if(day == "Sábado"){
        array = horariosSabado.map((horario) => {
          if(horario.posicion == pos){
            horario.closing = value;
          }
          return horario;
        });

        setHorariosSabado([...array]);
      }
    }

    // Cambiar capacidad del horario
    if(type == 3){
      if(day == "Domingo"){
        array = horariosDomingo.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosDomingo([...array]);
      }
      if(day == "Lunes"){
        array = horariosLunes.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosLunes([...array]);
      }
      if(day == "Martes"){
        array = horariosMartes.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosMartes([...array]);
      }
      if(day == "Miércoles"){
        array = horariosMiercoles.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosMiercoles([...array]);
      }
      if(day == "Jueves"){
        array = horariosJueves.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosJueves([...array]);
      }
      if(day == "Viernes"){
        array = horariosViernes.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosViernes([...array]);
      }
      if(day == "Sábado"){
        array = horariosSabado.map((horario) => {
          if(horario.posicion == pos){
            horario.capacity = value;
          }
          return horario;
        });

        setHorariosSabado([...array]);
      }
    }
    
  };

  const formatearHora = (hora) => {
    const constantDate = new Date('1906-07-24'); // Fecha constante
    const openingTime = hora; // Hora en formato "H:i:s"

    const openingDateTime = new Date(constantDate);

    const [openingHour, openingMinute] = openingTime.split(':');
    openingDateTime.setHours(openingHour, openingMinute);
    return openingDateTime;
  }

  const saveChanges = async () => {

    let array = [];
    array = [...statusHorarios, ...horariosDomingo, ...horariosLunes, 
    ...horariosMartes, ...horariosMiercoles, ...horariosJueves, ...horariosViernes,
    ...horariosSabado]

    try {
      const { success, message } = await RestaurantsApi
        .autoServiceDay(array);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (err) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const discardChanges = () => {
    setHasEdit(false);
    setReload(true);
    setLocalReload(true);
  };

  const noHayHorariosDia = (dia) => {
    let resultado = false;
    if(dia == "Domingo" && horariosDomingo.length == 0){
      resultado = true;
    }
    if(dia == "Lunes" && horariosLunes.length == 0){
      resultado = true;
    }
    if(dia == "Martes" && horariosMartes.length == 0){
      resultado = true;
    }
    if(dia == "Miércoles" && horariosMiercoles.length == 0){
      resultado = true;
    }
    if(dia == "Jueves" && horariosJueves.length == 0){
      resultado = true;
    }
    if(dia == "Viernes" && horariosViernes.length == 0){
      resultado = true;
    }
    if(dia == "Sábado" && horariosSabado.length == 0){
      resultado = true;
    }

    return resultado;

  }

  const renderHorario = (horario, element) => (
    <>
      <div key={uuid()} className="w502 dFlex flex-column dashedBorderLeft">
        <small className="font__commutter textCenter">N. MAXIMO DE PERSONAS</small>
        <NumberIncrements
          data={horario}
          value={horario.capacity}
          setNumber={(newCapacity) => {
            // Llama a manejadorDeHorarios con el nuevo valor de capacidad
            console.log('====================================');
            console.log(newCapacity.capacity);
            console.log('====================================');
            manejadorDeHorarios(3, newCapacity.capacity, horario.dia, horario.posicion); 
          }}
          isActive={Boolean(element.status)}
        />
      </div>
      <div key={uuid()} className="w502 dFlex flex-column ">

        <small className="font__commutter textCenter">INICIO</small>
        <KeyboardTimePicker
          ampm
          margin="normal"
          id="time-picker-closing"
          format="HH:mm"
          value={formatearHora(horario.opening)}
          onChange={(time, value) => {
            console.log('====================================');
            console.log(time);
            console.log('====================================');
            console.log('====================================');
            console.log(value);
            console.log('====================================');
            manejadorDeHorarios(1, value + ":00", horario.dia, horario.posicion); 
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          className="restaurant-schedule__time-picker"
          invalidDateMessage="No es un formato válido"
          keyboardIcon={<AccessTimeIcon />}
          InputProps={{ readOnly: true }}
          inputVariant="standard"
          InputAdornmentProps={{ position: 'start', border: 0 }}
          cancelLabel="CANCELAR"
          okLabel="OK"
          disabled={!Boolean(element.status)}
        />

      </div>
      <div key={uuid()} className="w502 dFlex flex-column">
        <small className="font__commutter textCenter">FIN</small>
        <KeyboardTimePicker
          ampm
          margin="normal"
          id="time-picker-closing"
          format="HH:mm"
          value={formatearHora(horario.closing)}
          onChange={(time, value) => { 
            console.log('====================================');
            console.log(time);
            console.log('====================================');
            console.log('====================================');
            console.log(value);
            console.log('====================================');
            manejadorDeHorarios(2, value + ":00", horario.dia, horario.posicion);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          className="restaurant-schedule__time-picker"
          invalidDateMessage="No es un formato válido"
          keyboardIcon={<AccessTimeIcon />}
          InputProps={{ readOnly: true }}
          inputVariant="standard"
          InputAdornmentProps={{ position: 'start', border: 0 }}
          cancelLabel="CANCELAR"
          okLabel="OK"
          disabled={!Boolean(element.status)}
        />
      </div>
    </>
  )

  const renderView = () => (
    <>

      <Hidden xsDown>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <div className="restaurantSchedule-form-container mr-22 ">

            <p>
              <b> Reservas personalizadas</b>
              {' '}
              Personaliza los dias, la cantidad de personas que se aceptan atumaticamente y
              los horarios disponibles de tus reservas
            </p>
            <div className="dflex justify-content-end textCenter">
              <div className="column662 dFlex flex-row textCenter">
                <b>HORAS DE RESERVA</b>
              </div>
              <div className="column662 dFlex flex-row textCenter">
                <b>HORAS DE RESERVA</b>
              </div>
            </div>

            {
              // eslint-disable-next-line no-return-assign
              statusHorarios.map((element, i) => (

                <div key={uuid()} className="restaurantSchedule-area-option pb-1 pt-1">
                  <div className="column33 dFlex flex-column">
                    {i === 0 ? (
                      <small className="font__commutter">
                        DÍA

                      </small>
                    ) : ''}
                    <FormControlLabel
                      key={uuid()}
                      label={element.dia}
                      control={(
                        <Checkbox
                          checked={Boolean(element.status)}
                          name={element.dia}
                          disableRipple
                          color="primary"
                          onChange={() => { changeCheckbox(element.dia); }}
                        />
                      )}
                    />
                  </div>

                  <div className="column662 dFlex flex-row">
                    { noHayHorariosDia(element.dia) && (
                        <div className="w502 dFlex flex-column">
                          <div className="w502 dFlex flex-column dashedBorderLeft" style={{ paddingTop: i === 0 ? '43px' : '0' }}>
                            <Grid item>
                              <Button
                                onClick={() => {
                                  toggleContent(element.dia);
                                }}
                                variant="contained"
                                className="custom-button"
                                style={{
                                  backgroundColor: '#000000',
                                  borderColor: 'black',
                                  color: '#ffffff',
                                }}
                              >
                                Nuevo Horario

                                Personalizado
                              </Button>
                            </Grid>
                          </div>
                        </div>
                      )
                      
                    }

                    {element.dia == "Domingo" && horariosDomingo.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Lunes" && horariosLunes.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Martes" && horariosMartes.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Miércoles" && horariosMiercoles.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Jueves" && horariosJueves.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Viernes" && horariosViernes.map((horario) => renderHorario(horario, element))}

                    {element.dia == "Sábado" && horariosSabado.map((horario) => renderHorario(horario, element))}
                    
                    {!noHayHorariosDia(element.dia) && (
                      <div className="w502 dFlex flex-column dashedBorderLeft" style={{ paddingTop: i === 0 ? '43px' : '0' }}>
                        <Grid item>
                          <Button
                            onClick={() => {
                              toggleContent(element.dia);
                            }}
                            variant="contained"
                            className="custom-button"
                            style={{
                              backgroundColor: '#000000',
                              borderColor: 'black',
                              color: '#ffffff',
                              padding: '3px 16px',
                            }}
                          >
                            Nueva Reserva

                            Personalizada
                          </Button>
                        </Grid>
                        <Grid item style={{ paddingTop: '3px' }}>
                          <Button
                            onClick={() => {
                              borrarHorario(element.dia);
                            }}
                            variant="contained"
                            className="custom-button"
                            style={{
                              backgroundColor: '#000000',
                              borderColor: 'black',
                              color: '#ffffff',
                            }}
                          >

                            Borrar Horario
                          </Button>
                        </Grid>
                      </div>
                    )}
                  </div>
                </div>
              ))
            }

            <div className="restaurantSchedule__cta-container">
              <Button
                variant="contained"
                color="secondary"
                type="button"
                onClick={() => { discardChanges(); }}
                disabled={!hasEdit}
              >
                CANCELAR
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                onClick={() => { saveChanges(); }}
                disabled={!hasEdit}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Hidden>
      <Hidden smUp>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
          <div className="restaurantSchedule-form-container mr-2">
            <div className="info-message-container mb-1 font__roboto dFlex flex-row justify-start align-center">
              <InfoIcon className="info-icon-tooltip mr-1" />
              <p>
                RESERVAS PERSONALIZADAS
              </p>
            </div>
            <div className="dflex justify-content-start">
              <div className="column662 dFlex flex-row">
                <b>HORAS DE RESERVA</b>
              </div>
            </div>
            
            {/* Aquí va la data */}
            <div className="dflex justify-content-start" />

            <div className="restaurantSchedule__cta-container">
              <div className="margintopfix">
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => { discardChanges(); }}
                  disabled={!hasEdit}
                >
                  CANCELAR
                </Button>
              </div>
              <Button
                className="mt-0"
                variant="contained"
                color="primary"
                type="button"
                onClick={() => { saveChanges(); }}
                disabled={!hasEdit}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Hidden>
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-5"
      >
        <Grid container>
          <Grid item xs={11} md={12} className="restaurantSchedule-right-grid">
            {statusHorarios.length > 0 && !isLoading && renderView()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

RestaurantSchedule.propTypes = {
  setReload: PropTypes.func,
};

RestaurantSchedule.defaultProps = {
  setReload: () => { },
};

export default RestaurantSchedule;
