/* eslint-disable */

import React, { useState, useEffect } from 'react';

/**
 * @description
 * Esta funcionalidad permite a los clientes realizar reservas para actividades directamente desde la plataforma,
 * recibiendo el restaurante notificaciones internas al respecto. Además, el restaurante puede ofrecer a los clientes la
 * opción de reservar a través de la página para aprovechar promociones específicas, tales como:
 * • Descuentos para titulares de la tarjeta del banco X, en colaboración con dicho banco.
 * • Ofertas especiales de una marca de vinos, reservando con anticipación.
 * • Participación en Restaurant Week.
 * • Acceso a eventos o presentaciones de música en vivo.
 */

import {
  Box,
  Button,
  Grid,
  Checkbox,
  TextField,
  Typography,
  Modal,
} from '@material-ui/core/';
import { v4 as uuid } from 'uuid';

import SnackbarAlert from '../SnackbarAlert';

import * as RestaurantsApi from '../../api/restaurants/restaurants';

import './RestaurantSpecialReservations.scss';

const RestaurantSpecialReservations = () => {
  const [botones, setBotones] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });

  const [openDemoModal, setOpenDemoModal] = useState(false);

  const handleOpenDemoModal = () => {
    setOpenDemoModal(true);
  };

  const handleCloseDemoModal = () => {
    setOpenDemoModal(false);
  };

  const obtenerBotones = async () => {
    try {
      const data = await RestaurantsApi.getBotones();
      if ('data' in data) {
        setBotones(data.data);
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'botones Cargados',
        });
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'No se pudieron obtener los botones',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: error.toJSON().message,
      });
    }
  };

  useEffect(() => {
    obtenerBotones();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const guardarMenu = async (id) => {
    try {
      const status = document.querySelector(`#check-boton-${id}`).checked;
      const descripcion = document.querySelector(`#description-boton-${id}`).value;
      const titulo = document.querySelector(`#boton-titulo-${id}`).value;
      const { data } = await RestaurantsApi.updateBotones({
        // eslint-disable-next-line react-hooks/exhaustive-deps
        id: id,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        description: descripcion,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        title: titulo,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        status: status,
      });
      if (data === 'Reserva actualizada exitosamente.') {
        setSnackbar({
          open: true,
          severity: 'success',
          text: 'Botón actualizado correctamente',
        });
        obtenerBotones();
        // setTimeout(() => {
        //   setReload(true);
        // }, 1500);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: 'El Botón no pudo ser actualizados correctamente',
        });
      }
    } catch (error) {
      console.log(error);
      setSnackbar({
        open: true,
        severity: 'error',
        text: 'Error',
      });
    }
  };

  const renderForm = () => (
    <div className="w100 dFlex flex-column align-center justify-center">
      {botones.map((menu, i) => (
        <div className="w75 card-menu" key={uuid()}>
          <div>
            <div className="dFlex justify-between">
              <h2>
                Botón
                {` ${i + 1}`}
              </h2>
              <div>
                <Checkbox
                  defaultChecked={Boolean(menu.status)}
                  id={`check-boton-${menu.id}`}
                  disableRipple
                  color="primary"
                  onChange={() => { guardarMenu(menu.id); }}
                />
              </div>
            </div>
            <div className="mb-3">
              <h3>
                Titulo
              </h3>
              <div className="restaurantMenu-form__field-container">
                <TextField
                  key={uuid()}
                  className="border-bottom-lite w100"
                  id={`boton-titulo-${menu.id}`}
                  defaultValue={menu.title}
                  disabled={!menu.status}
                />
              </div>
            </div>
            <div className="mb-3">
              <h3>
                Descripción
              </h3>
              <div className="restaurantMenu-form__field-container">
                <TextField
                  key={uuid()}
                  className="border-bottom-lite w100"
                  id={`description-boton-${menu.id}`}
                  defaultValue={menu.description}
                  disabled={!menu.status}
                />
              </div>
            </div>
          </div>
          <div className="dflex justify-end">
            <div className="restaurantMenu-form__buttonGroup mt-1">
              <Button
                variant="contained"
                color="primary"
                onClick={() => { guardarMenu(menu.id); }}
              >
                GUARDAR
              </Button>
            </div>
          </div>
        </div>
      ))}
      {/* Fin botones nuevos */}
    </div>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="RestaurantSpecialReservations-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                Reservas Especiales
              </Typography>
              <div>
                <Typography variant="body1">
                  Esta funcionalidad permite a los clientes realizar reservas para actividades directamente desde la plataforma,
                  recibiendo el restaurante notificaciones internas al respecto.
                </Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>
                  Además, el restaurante puede ofrecer a los clientes la opción de reservar a través de la página para aprovechar promociones específicas, tales como:
                </Typography>
                <ul style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                  <Typography component="li" variant="body1">Descuentos para titulares de la tarjeta del banco X, en colaboración con dicho banco.</Typography>
                  <Typography component="li" variant="body1">Ofertas especiales de una marca de vinos, reservando con anticipación.</Typography>
                  <Typography component="li" variant="body1">Participación en Restaurant Week.</Typography>
                  <Typography component="li" variant="body1">Acceso a eventos o presentaciones de música en vivo.</Typography>
                </ul>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    style={{ 
                      backgroundColor: 'black',
                      color: 'white',
                      marginTop: '1rem'
                    }}
                    onClick={handleOpenDemoModal}
                  >
                    DEMO
                  </Button>
                </Box>

                <Modal
                  open={openDemoModal}
                  onClose={handleCloseDemoModal}
                  aria-labelledby="demo-modal"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: 'white',
                      padding: '2rem',
                      maxWidth: '90%',
                      maxHeight: '90vh',
                      overflowY: 'auto',
                      borderRadius: '8px',
                    }}
                  >
                    <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                      Así es como aparece la oferta en la parte interna del restaurante
                    </Typography>
                    <Box display="flex" justifyContent="center" mb={3}>
                      <img
                        src="images/imagen.png"
                        alt="Vista interna de la oferta"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    </Box>
                    <Typography variant="h6" style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                      Así es como le aparece al cliente la oferta
                    </Typography>
                    <Box display="flex" justifyContent="center">
                      <img
                        src="images/imagen2.png"
                        alt="Vista del cliente de la oferta"
                        style={{ maxWidth: '100%', height: 'auto' }}
                      />
                    </Box>
                  </Box>
                </Modal>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            {renderForm()}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RestaurantSpecialReservations;
