import React, { useContext } from 'react';
import {
  Box,
  Tabs,
  Tab,
} from '@material-ui/core/';

import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import './RestaurantTabs.scss';

const RestaurantTabs = () => {
  // const [value, setValue] = useState(0);
  const { tab, setTab, isHaveSpecialReserve } = useContext(RestaurantContext);

  let tabData = [
    {
      name: 'description',
      label: 'DESCRIPCIÓN',
      tabId: 1,
    },
    {
      name: 'photos',
      label: 'FOTOGRAFÍAS',
      tabId: 2,
    },
    {
      name: 'capacity',
      label: 'CAPACIDAD',
      tabId: 3,
    },
    {
      name: 'menu',
      label: 'MENÚ',
      tabId: 4,
    },
    {
      name: 'schedule',
      label: 'HORARIO',
      tabId: 5,
    },
    {
      name: 'PORTADA',
      label: 'PORTADA',
      tabId: 6,
    },
    {
      name: 'reservations',
      label: 'Reservaciones automaticas',
      tabId: 7,
    },
    {
      name: 'reservationsday',
      label: 'Reservaciones Personalizadas',
      tabId: 8,
    },
  ];

  const getTabsData = () => {
    if (isHaveSpecialReserve) {
      tabData = [
        {
          name: 'description',
          label: 'DESCRIPCIÓN',
          tabId: 1,
        },
        {
          name: 'photos',
          label: 'FOTOGRAFÍAS',
          tabId: 2,
        },
        {
          name: 'capacity',
          label: 'CAPACIDAD',
          tabId: 3,
        },
        {
          name: 'menu',
          label: 'MENÚ',
          tabId: 4,
        },
        {
          name: 'schedule',
          label: 'HORARIO',
          tabId: 5,
        },
        {
          name: 'PORTADA',
          label: 'PORTADA',
          tabId: 6,
        },
        {
          name: 'reservations',
          label: 'Reservaciones automaticas',
          tabId: 7,
        },
        {
          name: 'reservationsday',
          label: 'Reservaciones Personalizadas',
          tabId: 8,
        },
        {
          name: 'specialreservations',
          label: 'Reservaciones Especiales',
          tabId: 9,
        },
      ];
    } else {
      tabData = [
        {
          name: 'description',
          label: 'DESCRIPCIÓN',
          tabId: 1,
        },
        {
          name: 'photos',
          label: 'FOTOGRAFÍAS',
          tabId: 2,
        },
        {
          name: 'capacity',
          label: 'CAPACIDAD',
          tabId: 3,
        },
        {
          name: 'menu',
          label: 'MENÚ',
          tabId: 4,
        },
        {
          name: 'schedule',
          label: 'HORARIO',
          tabId: 5,
        },
        {
          name: 'PORTADA',
          label: 'PORTADA',
          tabId: 6,
        },
        {
          name: 'reservations',
          label: 'Reservaciones automaticas',
          tabId: 7,
        },
        {
          name: 'reservationsday',
          label: 'Reservaciones Personalizadas',
          tabId: 8,
        },
      ];
    }
  };

  getTabsData();

  const handleChange = (event, newValue) => {
    localStorage.setItem('RestaurantCurrentTab', newValue);
    setTab(newValue);
  };

  return (
    <Box
      component="div"
      className="restaurant__tabs-box  dFlex flex-column w100"
    >
      <Box
        component="div"
        className="w100"
      >
        <Tabs
          className="w100"
          variant="scrollable"
          scrollButtons="on"
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {
            tabData.map((tabInfo) => (
              <Tab
                value={tabInfo.tabId}
                key={tabInfo.name}
                label={(
                  <div className="">
                    <span className="font__commutter">
                      {tabInfo.label}
                    </span>
                  </div>
                )}
              />
            ))
          }
        </Tabs>
      </Box>
    </Box>
  );
};
// ReservationsTabs.propTypes = {
//   // tabData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   // currentZone: PropTypes.shape({}).isRequired,
//   // setCurrentZone: PropTypes.func.isRequired,
// };
export default RestaurantTabs;
