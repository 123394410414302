import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
// lodash is imported from create-react-app, but we don't have it on our project dependecies.
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty, throttle } from 'lodash';
import {
  Box,
  Button,
  Container,
  // Grid,
  // Hidden,
  IconButton,
  TextField,
  Typography,
  Hidden,
  Popper,
  Backdrop,
  CircularProgress,
  makeStyles,
  Modal,
} from '@material-ui/core/';

import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import * as OffersApi from '../../api/restaurants/offers';

import isTruthy from '../../utils/isTruthy';

import OffersContext from './OffersContext/OffersContext';

import prepareOffersTable from '../../utils/prepareOffersTable';
import offersTableFilter from '../../utils/OffersTableFilter';
import OffersTable from '../../components/OffersTable';
import OffersAccordion from '../../components/OffersAccordion';
import TableErrors from '../../components/TableErrors';
import SnackbarAlert from '../../components/SnackbarAlert';
import TheMenu from '../../components/TheMenu';

import './Offers.scss';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const currentCallConfig = () => {
  const retrieveOffersConfig = localStorage.getItem('OffersConfig');
  let config = {
    lastPage: 1,
    currentPage: 1,
    perPage: 5,
    total: 0,
    from: 1,
    to: 5,
    options: [5, 25, 100],
  };
  if (retrieveOffersConfig !== null) {
    config = {
      lastPage: JSON.parse(retrieveOffersConfig).lastPage,
      currentPage: JSON.parse(retrieveOffersConfig).currentPage,
      perPage: Number(JSON.parse(retrieveOffersConfig).perPage),
      total: JSON.parse(retrieveOffersConfig).total,
      from: JSON.parse(retrieveOffersConfig).from,
      to: JSON.parse(retrieveOffersConfig).to,
      options: [5, 25, 100],
    };
  }
  return config;
};

const Offers = () => {
  const throttleTime = 200;
  const [state, setState] = useState({
    loading: true,
    error: null,
    success: false,
    offers: [],
    paginator: {},
  });

  const [offers, setOffers] = useState(state.offers);
  const [filteredOffers, setFilteredOffers] = useState(state.offers);
  const [reload, setReload] = useState(false);

  // Los siguientes son usados para configurar el buscador de la página
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopper = Boolean(anchorEl);
  const [nameForFilter, setNameForFilter] = useState('');

  // Configuración para el nackbar alert
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  // Configuracion para el backdrop/loader
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  // Configuracion para el popper de búsqueda
  const handlePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [modalMesaDiferenteZona, setModalMesaDiferenteZona] = useState(false);

  const cerrarModalMesaDiferenteZona = () => {
    setModalMesaDiferenteZona(false);
  };

  useEffect(() => {
    setState({
      loading: true,
      error: null,
      success: false,
      offers: [],
      paginator: {},
    });
    const fetchOffers = async () => {
      const newConfig = currentCallConfig();
      try {
        const { success, data } = await OffersApi
          .showAllOffers(newConfig);
        if (isTruthy(success)) {
          await prepareOffersTable(data).then((tableData) => {
            setOffers(tableData);
            console.log(JSON.stringify(tableData));
            setFilteredOffers(tableData);
            const newCallConfig = {
              currentPage: data.currentPage,
              from: data.from,
              perPage: data.perPage,
              lastPage: data.lastPage,
              to: data.to,
              total: data.total,
              options: [5, 25, 100],
            };
            console.log(newCallConfig);
            console.log('se setea');
            localStorage.setItem('OffersConfig', JSON.stringify(newCallConfig));
            setState({
              loading: false,
              error: null,
              success: true,
              offers: tableData,
              paginator: newCallConfig,
            });
          });
          setReload(false);
        } else {
          setState({
            loading: false,
            error:
              data.error,
            success: false,
            offers: [],
            paginator: {},
          });
        }
      } catch (error) {
        console.log(error);
        setState({
          loading: false,
          error: error.message,
          success: false,
          offers: [],
          paginator: {},
        });
      }
    };
    fetchOffers();
  }, [reload]);

  useEffect(() => {
    const newFilteredOffers = isEmpty(nameForFilter)
      ? offers
      : offers.filter((member) => offersTableFilter(member, nameForFilter));
    setFilteredOffers(newFilteredOffers);
  }, [nameForFilter, offers]);

  const createOffers = () => {
    navigate('/crear-oferta');
  };

  const renderLoading = () => (
    <div>Loading...</div>
  );

  const renderError = () => {
    const errorData = {
      message: state.error,
      action: () => true,
      buttonText: 'Intentar de nuevo',
      image: 'broken',
    };
    return (
      <TableErrors data={errorData} action={setReload} />
    );
  };

  const renderTable = () => (
    <>
      <Hidden xsDown>
        <Box
          component="div"
          className="offers-table__box w100 dFlex flex-row align-center justify-between"
        >
          <OffersContext.Provider value={
            { filteredOffers, setReload }
          }
          >
            <OffersTable />
          </OffersContext.Provider>
        </Box>
      </Hidden>
      <Hidden smUp>
        <Box
          component="div"
          className="offers-table__box w100 dFlex flex-row align-center justify-between"
        >
          <OffersContext.Provider value={
            { filteredOffers, setReload }
          }
          >
            <OffersAccordion />
          </OffersContext.Provider>
        </Box>
      </Hidden>
    </>
  );

  return (
    <>
      <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <TheMenu setIsCalendarOpen={() => { }} />
      <Container
        className="offers layout px-0"
      >
        <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
        <Box
          component="div"
          className="offers__header pr-1 pl-1 dFlex flex-row align-center justify-between"
        >
          <Hidden smUp>
            <IconButton
              onClick={handlePopper}
              edge="start"
              color="primary"
              aria-label="menu"
              size="small"
            >
              <SearchIcon />
            </IconButton>
          </Hidden>
          <div className="dFlex flex-row">
            <Hidden xsDown>
              <IconButton
                onClick={handlePopper}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
                className="search-icon__button"
              >
                <SearchIcon />
              </IconButton>
            </Hidden>
            <Typography variant="h5" className="offers__title font__commutter">
              EXPERIENCIAS
            </Typography>
          </div>
          <Popper
            className="offers__searchBar"
            open={openPopper}
            anchorEl={anchorEl}
            placement="right"
            disablePortal={false}
            transition
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: 'scrollParent',
                hide: false,
              },
            }}
          >
            <div>
              <TextField
                id="standard-basic"
                placeholder="Buscar"
                onChange={throttle((e) => setNameForFilter(e.target.value), throttleTime)}
                value={nameForFilter}
              />
              <IconButton
                onClick={(e) => { handlePopper(e); setNameForFilter(''); }}
                edge="start"
                color="primary"
                aria-label="menu"
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Popper>
          <Hidden xsDown>
            <div>
              <Button
                onClick={() => { setModalMesaDiferenteZona(true); }}
                variant="contained"
                color="primary"
                className="mr-1e"
              >
                EXPLICAR SECCIÓN
              </Button>
              <Button
                onClick={() => { createOffers(); }}
                variant="contained"
                color="primary"
              >
                CREAR NUEVA EXPERIENCIA
              </Button>
              <Modal
                keepMounted
                open={modalMesaDiferenteZona}
                onClose={cerrarModalMesaDiferenteZona}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box
                  className="modal__box3 pl-1 pb-1 pt-1 pr-1"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '50%',
                    maxWidth: 600,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    mt: 1,
                    '&:focus': {
                      outline: 'none',
                    },
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                      bgcolor: 'background.paper',
                    }}
                  >
                    <Typography variant="body1" sx={{ lineHeight: 1.4 }}>
                      La sección &quot;Experiencias&quot; permite informar a los clientes sobre
                      actividades especiales del restaurante, como los días y horarios
                      en los que se ofrecen:
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      p: 2,
                      flex: 1,
                      overflowY: 'auto',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    <ul style={{ margin: 0, paddingLeft: '24px' }}>
                      <li>Música en vivo</li>
                      <li>Happy Hour</li>
                      <li>Restaurant Week</li>
                      <li>Transmisión de eventos deportivos</li>
                      <li>Productos de temporada</li>
                    </ul>

                    <Typography variant="body1">
                      Para ver cómo se presentan estas experiencias a los clientes, visiten
                      la web:
                      <a
                        href="https://reservaya.com.do/home"
                        style={{ color: '#1976d2', textDecoration: 'none', marginLeft: '8px' }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://reservaya.com.do/home
                      </a>
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      <img
                        src="images/3eb8ba1e-a28c-4905-97d5-3b30949498e0.jpg"
                        alt="Imagen explicativa de experiencias"
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                      <img
                        src="images/Diseño.jpg"
                        alt="Diseño"
                        style={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </div>
          </Hidden>
          <Hidden smUp>
            <IconButton
              onClick={() => { createOffers(); }}
              edge="start"
              color="secondary"
              aria-label="menu"
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Hidden>
        </Box>

        <Box
          component="div"
          className="offers__tabs-container mt-2 dFlex flex-column align-center justify-between"
        >
          {!state.loading && state.error && renderError()}
          {!state.loading && filteredOffers.length > 0 && renderTable()}
          {state.loading && renderLoading()}
        </Box>
      </Container>
    </>
  );
};

export default Offers;
