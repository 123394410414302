/* eslint max-len: 0 */

import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@material-ui/core/';

import * as AuthApi from '../../api/auth';

import TheMenu from '../../components/TheMenu';

import './Privacy.scss';

const Privacy = () => (
  <>
    { AuthApi.getAccessToken() && (<TheMenu setIsCalendarOpen={() => { }} />)}
    <Container
      className="restaurant layout px-0"
    >
      <Box
        component="div"
        className="privacy__header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <Typography variant="h5" className="font__commutter">
          Política de Privacidad de RESERVA YA
        </Typography>
      </Box>
      <Box
        component="div"
        className="privacy__text-header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <div className="dFlex flex-column">
          <Typography variant="body1" className="restaurant__title font__roboto pb-4">
            <strong>1. INTRODUCCIÓN</strong>
            <br />
            <br />
            En Reserva Ya, valoramos su privacidad y estamos comprometidos a proteger sus datos personales. Esta política detalla cómo recopilamos, utilizamos y compartimos su información en relación con la publicidad y las ofertas de marcas promocionadas en nuestra plataforma, de acuerdo con las leyes de la República Dominicana.
            <br />
            <br />
            <strong>2. ¿QUÉ INFORMACIÓN RECOLECTAMOS?</strong>
            <br />
            <br />
            - Información que el Restaurante provea a Reserva Ya para el propósito del registro y pago en nuestra aplicación incluyendo email, nombre, apellido, foto o avatar, lugar y país de residencia, dirección, teléfono y contraseña de ingreso al sitio.
            <br />
            - Cualquier otra información que el Restaurante añada a su perfil de la plataforma.
            <br />
            <br />
            <strong>3. USO DE DATOS PERSONALES</strong>
            <br />
            <br />
            Cuando el Restaurante utiliza o visita la Plataforma, autoriza a RESERVA YA a recolectar información de este directamente. También queda autorizada a generar información sobre el Restaurante (por ejemplo, información sobre si usa la aplicación móvil o la web de RESERVA YA).
            <br />
            <br />
            RESERVA YA utiliza la información del Restaurante para proporcionar los Servicios, adaptar su experiencia, mostrarle reservas realizadas a través de la Plataforma, enviarle comunicaciones de marketing, ofrecerle publicidad y ofertas más relevantes que puedan ser de interés y propósitos del Restaurante.
            <br />
            <br />
            Así mismo, mediante el uso de la Plataforma, el Restaurante autoriza a RESERVA YA a compartir su información con proveedores de servicios (incluidos los procesadores de pagos); con otros socios comerciales (incluidos socios publicitarios); con servicios de redes sociales; con servicios o aplicaciones de terceros que utiliza para iniciar sesión en su cuenta en la Plataforma. Por igual se autoriza a utilizar la información para proporcionar y mejorar los Servicios, para fines de marketing, y facilitar ofertas y publicidad de otros servicios que puedan interesarle.
            <br />
            <br />
            En ningún momento RESERVA YA almacena los datos de la tarjeta de crédito; esta tarea la realizan los proveedores de pagos online.
            <br />
            <br />
            RESERVA YA mantendrá medidas de seguridad técnicas, administrativas y físicas diseñadas para proteger la información del Usuario. De igual manera, quedará autorizada a retener información del Usuario mientras su cuenta esté activa y por un período de tiempo a partir de entonces para permitirle reactivar su cuenta sin pérdida de información, y según sea necesario.
            <br />
            <br />
            El Restaurante reconoce que la Reserva Ya utiliza cookies y tecnologías similares para ayudar a RESERVA YA a proporcionar los Servicios, y ofrecerle una experiencia al Usuario más personalizada y comercializar los productos y Servicios y los de terceros.
            <br />
            <br />
            Los datos personales ingresados en Reserva Ya, serán utilizados para los propósitos especificados en estas políticas de privacidad o en las secciones relevantes de la aplicación. La contraseña para acceder a la plataforma de RESERVA YA no será compartida. RESERVA YA no tiene archivo de datos biométricos, en el caso de su uso en la plataforma, este almacenamiento lo realizará el dispositivo electrónico desde donde el Usuario acceda a la aplicación y conforme a los términos de su sistema operativo.
            <br />
            <br />
            De igual manera, podremos usar la información personal del Restaurante recolectada para:
            <br />
            a. Mejorar la experiencia de navegación personalizando la Aplicación por el móvil/ el sitio web.
            <br />
            b. Habilitar al Restaurante para el uso de los servicios disponibles en la Aplicación por el móvil/ el sitio web.
            <br />
            c. Enviar publicidad, &quot;marketing&quot;, &quot;banners&quot; a los usuarios, el Restaurante, patrocinadores, anunciantes y relacionados comerciales de RESERVA YA.
            {' '}
            <br />
            d. Enviar al Restaurante, usuario notificaciones por mensajes privados.
            <br />
            e. Enviarle el newsletter y otros comunicados relacionados con RESERVA YA que a nuestro entender será de interés del Restaurante y usuario, vía whatsapp o cualquier otra forma digital de mensajería.
            <br />
            f. Manejar las consultas y quejas por el Usuario y Restaurante.
            <br />
            g. La información del usuario (correo electrónico, número de teléfono) puede ser compartida con la marca que realizó la promoción. Esta información será utilizada exclusivamente por la marca para fines de marketing, promociones y comunicación directa con el usuario.
            <br />
            <br />
            RESERVA YA no proveerá su información personal, a menos que otorgue su consentimiento expreso o Aceptación a las presentes Políticas de Privacidad y los Términos y Condiciones de RESERVA YA.
            <br />
            <br />
            El Restaurante puede informarnos en todo momento si no desea recibir los mensajes de publicidad. Opcionalmente puede modificar sus preferencias en la configuración de su cuenta o hacer click en la pestaña para &quot;Dar de baja&quot; en los mensajes de publicidad que reciba del servicio, para no cancelar el envío en próximos mensajes de Reserva Ya o escribirnos para darse de baja a
            {' '}
            <a href="mailto:contacto@reservaya.com">contacto@reservaya.com</a>
            .
            <br />
            <br />
            <strong>4. DECLARACIONES</strong>
            <br />
            <br />
            En RESERVA YA deberemos revelar información sobre el Restaurante a nuestros agentes y proveedores de servicios informáticos, mientras sea razonablemente necesario por los propósitos establecidos en esta política de privacidad. En adición, deberemos revelar información sobre el Usuario y Restaurante, a saber:
            <br />
            a. Hasta la medida que Reserva Ya sea requerida a hacerlo por ley.
            <br />
            b. En conexión a cualquier procedimiento legal o posible procedimiento legal.
            <br />
            c. Para establecer, ejercer o defender nuestros derechos legales (incluyendo proveer información a otros para los propósitos de prevención de fraude).
            <br />
            <br />
            Exceptuando a lo establecido en esta política de privacidad, RESERVA YA no suministrará su información personal a terceros.
            <br />
            <br />
            <strong>5. SEGURIDAD DE SUS DATOS PERSONALES</strong>
            <br />
            <br />
            Reserva Ya tomaremos precauciones razonables técnicas y organizacionales para prevenir la pérdida, mal uso o alteración de la información personal del Usuario. La información personal que el Usuario provea y RESERVA YA almacene, que como se ha indicado no incluye datos biométricos, se hará en servidores seguros detrás de firewalls protegidos con contraseña.
            <br />
            <br />
            La transmisión de datos a través de internet es inherentemente insegura, y RESERVA YA no puede garantizar la seguridad de los datos enviados a través de internet.
            <br />
            <br />
            RESERVA YA es responsable de mantener su contraseña y detalles de usuario confidencialmente.
            <br />
            <br />
            <strong>6. CORRECCIONES DE LA POLÍTICA</strong>
            <br />
            <br />
            Al usar o acceder a los Servicios o al hacer clic en &quot;acepto&quot; o &quot;apruebo&quot; este Acuerdo, el Restaurante admite que ha leído, comprende y acepta obligarse por lo dispuesto en este Acuerdo. Reserva Ya se reserva el derecho de modificar estos Términos de uso en cualquier momento, en su totalidad o en parte. Por lo tanto, es responsabilidad del Restaurante consultar periódicamente la última versión de los Términos de uso publicados y aceptar las actualizaciones que se produzcan en la plataforma. Se considera que los Restaurantes aceptan la última versión, con cada nuevo uso de la Plataforma.
            <br />
            <br />
            <strong>7. SUS DERECHOS</strong>
            <br />
            <br />
            El Restaurante podrá notificar al correo electrónico de RESERVA YA, su deseo de que no sean compartidos sus datos personales. Estos derechos son el acceso, la rectificación y la supresión de sus datos.
            <br />
            <br />
            <strong>8. SITIOS WEB DE TERCEROS</strong>
            <br />
            <br />
            El Restaurante reconoce que en la Plataforma se proporcionan referencias a otros sitios web de anunciantes, patrocinadores y relacionados comerciales, sobre los cuales libera de toda responsabilidad a RESERVA YA sobre el acceso, uso, y contenido en los mismos. Todas las conexiones que se proveen a otros sitios, tienen como único objetivo brindarles mayores referencias sobre ofertas, actividades, servicios ofrecidos de su interés. Las políticas y procedimientos que describimos aquí no se aplican a los servicios de terceros. Le sugerimos ponerse en contacto con estos sitios directamente para obtener información sobre sus términos y condiciones, políticas de privacidad, entre otros.
            <br />
            <br />
            <strong>9. ACTUALIZACIÓN DE LA INFORMACIÓN</strong>
            <br />
            <br />
            Si la información del Usuario ha cambiado, es responsable de modificarla en la Aplicación o Sitio web de RESERVA YA. Esto permitirá el correcto funcionamiento del sitio.
            <br />
            <br />
            <strong>10. BASE LEGAL</strong>
            <br />
            <br />
            El Restaurante reconoce y acepta que estas Políticas de Privacidad se rigen e interpretan de conformidad con las leyes de la República Dominicana, y en concordancia con las siguientes legislaciones y sus modificaciones: (i) Ley General de Protección de los Derechos al Consumidor o Usuario, núm. 358-05, (ii) Ley No. 172-13 que tiene por objeto la protección integral de los datos personales asentados en archivos, registros públicos, bancos de datos u otros medios técnicos de tratamiento de datos destinados a dar informes, sean estos públicos o privados, (iii) Ley No. 310-14 que Regula el envío de correos electrónicos no solicitados, y (iv) Ley No.126-02 sobre Comercio Electrónico, Documentos y Firmas Digitales de la República Dominicana.
            <br />
            <br />
            <strong>11. CONTACTO</strong>
            <br />
            <br />
            Si el Restaurante tiene cualquier pregunta sobre estas políticas de privacidad o el trato de RESERVA YA con sus datos personales, por favor escribanos un email a
            {' '}
            <a href="mailto:contacto@reservaya.com">contacto@reservaya.com</a>
            .
          </Typography>
        </div>
      </Box>
    </Container>
  </>
);

export default Privacy;
