import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  Alert,
} from '@mui/material';
import unsubscribeUser from '../../api/unsubscribe';

const Unsubscribe = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await unsubscribeUser(email);
      setStatus({
        type: 'success',
        message: 'Tu solicitud de desubscripción ha sido procesada exitosamente.',
      });
      setEmail('');
    } catch (error) {
      console.error('Error detallado:', error);
      const errorMessage = error.response?.data?.message
        || error.response?.data
        || error.message
        || 'Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente.';
      setStatus({
        type: 'error',
        message: errorMessage,
      });
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          backgroundColor: 'white',
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography component="h1" variant="h5" gutterBottom>
          Desuscripción de Publicidad
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 3 }}>
          Para dejar de recibir nuestra publicidad, por favor ingresa tu correo electrónico
          a continuación.
        </Typography>

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />

          {status.message && (
            <Alert severity={status.type} sx={{ mt: 2 }}>
              {status.message}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Confirmar Desuscripción
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Unsubscribe;
