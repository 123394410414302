/* eslint max-len: 0 */
import React from 'react';

import {
  Box,
  Container,
  Typography,
} from '@material-ui/core/';

import * as AuthApi from '../../api/auth';

import TheMenu from '../../components/TheMenu';

import './Terms.scss';

const Terms = () => (
  <>
    { AuthApi.getAccessToken() && (<TheMenu setIsCalendarOpen={() => { }} />)}
    <Container
      className="restaurant layout px-0"
    >
      <Box
        component="div"
        className="terms__header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <Typography variant="h5" className="font__commutter" style={{ color: 'black', textDecoration: 'underline' }}>
          Términos y Condiciones de uso de la APP “RESERVA YA”
        </Typography>
      </Box>
      <Box
        component="div"
        className="terms__text-header pr-1 pl-1 dFlex flex-row align-center justify-between"
      >
        <div className="dFlex flex-column">
          <Typography variant="body1" className="restaurant__title font__roboto pb-4">
            <strong>1. Aspectos Generales.</strong>
            <br />
            RESERVA YA, es el nombre comercial debidamente registrado de la plataforma de reservas de restaurantes en la República Dominicana, el cual pertenece a la sociedad denominada PUERTA 21 SRL., debidamente constituida bajo las leyes dominicanas, responsable jurídico del mencionado nombre comercial.
            <br />
            <br />
            Los términos y condiciones que aquí se establecen, representan el acuerdo legal (el &quot;Acuerdo&quot; o los &quot;Términos de Uso&quot;) entre usted (el &quot;Restaurante&quot;) y RESERVA YA (la plataforma), esta última como Intermediaria de los Servicios (según se describen más adelante). Al aceptar la invitación al registrarse en Reserva Ya como Restaurante de los Servicios, usted declara y reconoce haber leído completamente y aceptar incondicionalmente, a todas las cláusulas que aquí se establecen.
            {' '}
            <br />
            <br />
            RESERVA YA es una web y aplicación móvil diseñada con el fin de gestionar las reservas del restaurante forma digital.
            <br />
            <br />
            Al usar o acceder a los Servicios o al hacer clic en &quot;acepto&quot; o &quot;apruebo&quot; este Acuerdo, el Restaurante admite que ha leído, comprende y acepta obligarse por lo dispuesto en este Acuerdo.
            {' '}
            <br />
            <br />
            RESERVA YA se reserva el derecho de modificar estos Términos de uso en cualquier momento, en su totalidad o en parte. Por lo tanto, es responsabilidad del Restaurante consultar periódicamente la última versión de los Términos de uso publicados y aceptar las actualizaciones que se produzcan en la plataforma. Se considera que los Usuarios aceptan la última versión, con cada nuevo uso de la Plataforma.
            <br />
            <br />
            <strong>2. Reservas en Restaurantes.</strong>
            <br />
            RESERVA YA pone a disposición del Restaurante servicios de reservaciones en restaurantes (los &quot;Servicios de Reservación&quot;) mediante la Plataforma, con el objetivo de asistir al Restaurante en asegurar reservaciones en los restaurantes. En respuesta a la solicitud hecha, a través de la Plataforma, por un Usuario para obtener una reservación en un Restaurante, RESERVA YA se contacta con la base de datos computarizada del Restaurante para reservaciones. La disponibilidad de las reservaciones, el tiempo de espera estimado o el lugar en la fila en una lista de espera se determinan al momento de la solicitud del Usuario y según la información que el Restaurante le brinda a RESERVA YA. Una vez que el Usuario efectúa la reservación o la solicitud de lista de espera por medio de la Plataforma, RESERVA YA proporcionará al Usuario la confirmación a través de la Plataforma, vía correo electrónico u otros mensajes electrónicos, según lo haya acordado el Usuario. Al usar los Servicios, el Usuario acepta recibir las confirmaciones de reservación, actualizaciones, modificaciones y/o cancelaciones por la Plataforma, correo electrónico u otros mensajes electrónicos.
            {' '}
            <br />
            <br />
            <strong>3. Registro, Contraseña y Responsabilidad.</strong>
            <br />
            <strong>Navegación la Plataforma.</strong>
            <br />
            (a) Registro en la plataforma, y disfrutar de sus beneficios, el Restaurante deberá completar una serie de datos, con la finalidad de crear su perfil en la plataforma y gestionar sus reservas eficientemente.
            <br />
            (b) Su cuenta de Restaurante no debe incluir el nombre de otro Restaurante con la intención de suplantar a ese restaurante, o ser ofensivo, vulgar u obsceno, o contrario a la moral y a las buenas costumbres.
            <br />
            (c) Su nombre de Restaurante y contraseña son personales y, por tanto es el único responsable de mantener la confidencialidad de sus datos para el inicio de sesión en la Plataforma, del uso y acceso a los Servicios con su nombre de Restaurante y/o contraseña. Siempre tendrá habilitada la opción de recuperar su contraseña haciendo uso de su correo electrónico; sin perjuicio que nos notifique inmediatamente al darse cuenta de la pérdida, robo o uso no autorizado de cualquier contraseña, ID de usuario o dirección de correo electrónico.
            <br />
            (d) El Restaurante no queda autorizado a transferir o vender el acceso a su cuenta.
            <br />
            (e) RESERVA YA no será responsable de cualquier daño relacionado con la divulgación de su nombre de Restaurante o contraseña, o el uso que cualquier persona de a su nombre de Restaurante o contraseña.
            <br />
            (f) El Restaurante no puede usar la cuenta de otro usuario sin contar con el permiso de este último.
            <br />
            (g) RESERVA YA se reserva el derecho de requerir que el Restaurante cambie su nombre de usuario y/o contraseña si a su discreción entiende que su cuenta ya no es segura o si recibe una queja respecto de su nombre de usuario que viole los derechos de otra persona.
            <br />
            (h) RESERVA YA se reserva el derecho a rechazar el registro, cancelar su cuenta o no permitir el acceso a los Servicios, a modo de referencia, más no limitativo, en caso incumpla las condiciones de los Términos de Uso y de sus políticas o actúe en forma contraria a lo dispuesto en ellas, incurra en posibles infracciones de carácter legal y/o efectúe un uso inadecuado de la Plataforma.
            <br />
            <br />
            <strong>4. Información del Usuario.</strong>
            <br />
            Para fines de brindar una mejor experiencia al Restaurante, RESERVA YA recopilará ciertas informaciones, correspondientes, pero no limitadas a:
            <br />
            (a) Datos personales (como su nombre completo)
            <br />
            (b) Información de contacto (como dirección de correo electrónico, número de teléfono, redes sociales);
            <br />
            (c) Información acerca de preferencias de los Usuarios de la cuenta (Detalles de reservas de restaurantes actuales y anteriores; preferencias de comidas; restaurantes favoritos; solicitudes especiales de restaurantes; actividad gastronómica, incluida la frecuencia, tipo de restaurante y cancelaciones de reservas);
            <br />
            (d) Información de la cuenta (como la configuración de la cuenta y las contraseñas);
            <br />
            (e) Datos de redes sociales (si elige vincular su cuenta de la Plataforma con una cuenta de redes sociales, podemos recopilar información personal como nombre, edad, sexo, fotografía y otra información personal relacionada con su cuenta de redes sociales);
            <br />
            (f) Sus contactos y fotos (en su teléfono móvil);
            <br />
            (g) Sus preferencias (por encuestas y respuestas de marketing);
            <br />
            (h) Comunicaciones que nos envía (como atención al cliente, mensajes o comunicaciones a nosotros o con restaurantes, a través de la plataforma);
            <br />
            (i) Otra información que pueda proporcionar (incluida otra información que nos sea proporcionada, a través de plataformas de terceros).
            <br />
            <br />
            Cuando el Restaurante utiliza o visita la Plataforma, autoriza a RESERVA YA a recolectar información de este directamente (por ejemplo, cuando busca o hace una reserva en línea). También queda autorizada a generar información sobre el Restaurante (por ejemplo, información sobre su dispositivo mientras usa la Plataforma Reserva Ya). En algunos casos, también RESERVA YA obtiene información personal de terceros (por ejemplo, usuarios, socios comerciales, empresas de nuestro grupo u otros terceros).
            <br />
            <br />
            De igual forma, RESERVA YA utiliza la información del Restaurante para proporcionar los Servicios, adaptar su experiencia, mostrarle reservas realizadas a través de la Plataforma y empresas relacionadas, enviarle comunicaciones de marketing, ofrecerle publicidad y ofertas más relevantes que puedan ser de interés y para otros propósitos del Usuario.
            <br />
            <br />
            Así mismo, mediante el uso de la Plataforma, el Restaurante autoriza a RESERVA YA a compartir su información con empresas relacionadas; con proveedores de servicios (incluidos los procesadores de pagos); con otros socios comerciales (incluidos socios publicitarios); con servicios de redes sociales; con servicios o aplicaciones de terceros que utiliza para iniciar sesión en su cuenta en la Plataforma. Por igual se autoriza a utilizar la información para proporcionar y mejorar los Servicios, para fines de marketing, y facilitar ofertas y publicidad de otros servicios que puedan interesar al Restaurante.
            <br />
            <br />
            RESERVA YA mantendrá medidas de seguridad técnicas, administrativas y físicas diseñadas para proteger la información del Usuario. De igual manera, quedará autorizada a retener información del Usuario mientras su cuenta esté activa y por un período de tiempo a partir de entonces para permitirle reactivar su cuenta sin pérdida de información, y según sea necesario.
            <br />
            <br />
            El Restaurante reconoce que la Plataforma utiliza cookies y tecnologías similares para ayudar a RESERVA YA a proporcionar los Servicios, y ofrecerle una experiencia al Usuario más personalizada y comercializar los productos y Servicios y los de terceros.
            <br />
            <br />
            <strong>5. Propiedad Intelectual.</strong>
            <br />
            Todos los derechos de propiedad intelectual referidos al contenido y diseño de los Servicios son de propiedad de RESERVA YA o de terceros (y en aquellos casos en que sea de propiedad de terceros RESERVA YA cuenta con las licencias necesarias para utilizarlos con relación a los Servicios).
            <br />
            <br />
            El Restaurante no puede utilizar o reproducir ni permitir que nadie use o reproduzca cualquier marca registrada, nombre o logotipo que figure en los Servicios sin la autorización previa y por escrito de RESERVA YA.
            <br />
            <br />
            El software que opera la Plataforma y todas las aplicaciones digitales que se adhieren a este, no podrán ser usados o destinados por los Restaurantes para usos distintos a los usos permitidos bajo estos Términos de Uso. El Restaurante no podrá copiar, realizar ingeniería inversa, modificar o realizar cualquier transacción con el software de nuestros Servicios.
            <br />
            <br />
            <strong>6. Uso del Contenido.</strong>
            <br />
            Los Servicios que se ofrecen en Reserva Ya se encuentran disponibles solo para aquellas personas que puedan celebrar contratos legalmente vinculantes de acuerdo a lo establecido por la ley aplicable del país desde donde se usa la Plataforma.
            <br />
            <br />
            Al acceder a la Plataforma, declara ser mayor de 18 años de edad y que se encuentra facultado a asumir obligaciones vinculantes con respecto a cualquier tipo de responsabilidad que se produzca por el uso que efectúe de esta Plataforma y los Servicios.
            <br />
            <br />
            El contenido que posee esta Plataforma se ha preparado con la intención de brindar información sobre los Restaurantes y el servicio de reservas en los Restaurantes (en adelante, las &quot;Reservas&quot;), así como también, como una red social privada entre los Usuarios registrados.
            {' '}
            <br />
            <br />
            Ciertas informaciones contenidas en la Plataforma han sido proporcionadas directamente por los Restaurantes (tales como disponibilidad y tiempo de las Reservas, precios, horarios y días de servicio, código de vestimenta, área para fumadores y no fumadores, mínimo de personas por mesa, etc.), por lo que en caso de encontrar errores o falencias en la información provista, RESERVA YA no será responsable por las mismas, ni los daños que el uso o dependencia en ellas pueda causar.
            <br />
            <br />
            El Restaurante podrá emitir opiniones, comentarios, valoraciones, correos electrónicos, sugerencias y preguntas concernientes a, o relacionadas a las informaciones ofrecidas en la Plataforma, siempre y cuando, el contenido no sea, de manera enunciativa y no limitativa, ilegal, amenazante, obsceno, racista, difamatorio, calumnioso, pornográfico y que no infrinja lo establecido en los Términos de Uso y demás políticas que forman parte integrante de los Términos de Uso.
            <br />
            <br />
            Se prohíbe expresamente la utilización de esta Plataforma con fines comerciales. El Usuario no podrá ingresar a la Plataforma por medio de técnicas de acceso automatizadas o de scraping u otro dispositivo automático, salvo que cuente con autorización previa por escrito de Reserva Ya. Tampoco podrá utilizar cualquier dispositivo o software que interfiera o intente interferir con el funcionamiento de la Plataforma.
            <br />
            <br />
            El Restaurante no deberá utilizar la Plataforma, para, a modo de referencia, más no limitativo: enviar mensajes directos, ni en el foro, ni correos electrónicos masivos (spam) con contenido amenazante, abusivo, hostil, ultrajante, difamatorio, vulgar, obsceno o injurioso; publicar contenido falso, incorrecto, engañoso, calumnioso o difamatorio; recopilar información de los usuarios; alentar, incitar o dirigir a otros usuarios a infringir los presentes de Términos de Uso o alguna disposición legal; para llevar a cabo algún acto ilegal o delictivo; sacar una ventaja indebida, ilegítima o de mala fe; interferir con la capacidad de otros usuarios de acceder o utilizar la Plataforma. Además, acepta que no: copiará, reproducirá, modificará, creará derivaciones, distribuirá o mostrará públicamente algún contenido de la Plataforma; usará un lenguaje ilícito, abusivo, amenazante, obsceno, vulgar, racista, ni cualquier lenguaje que se considere inapropiado o en contra de la moral y las buenas costumbres; anunciar o proporcionar enlaces a sitios que contengan material ilegal u otro contenido que pueda dañar o deteriorar la red personal o computadora de otro usuario.
            <br />
            <br />
            Los Servicios y contenidos de RESERVA YA se ofrecen únicamente para uso del Restaurante y para los fines descritos en este Acuerdo. Está prohibido cualquiera y todos los otros usos. RESERVA YA expresamente se reserva todos los derechos y recursos conforme a las leyes aplicables.
            <br />
            <br />
            RESERVA YA se reserva el derecho, a su exclusiva discreción, de rehusarse a brindar servicios, terminar cuentas, eliminar o editar contenidos, cancelar reservaciones o negar acceso a los Servicios. El Restaurante acepta no hacer, incurrir o causar ninguna de las siguientes actuaciones (y no permitir que los terceros lo hagan):
            <br />
            (1) Usar enlace profundo, robot, spider, scraper u otro dispositivo, proceso o medio automático o manual para acceder, copiar, buscar o controlar alguna parte de los Servicios o de los Contenidos de RESERVA YA, salvo que RESERVA YA lo autorice expresamente;
            <br />
            (2) Tomar medidas que impongan o puedan imponer (según la exclusiva consideración de RESERVA YA) una carga desproporcionada o excesivamente grande en los Servicios o en la infraestructura de RESERVA YA;
            <br />
            (3) Utilizar dispositivos, software o rutas que interfieran o intenten interferir con la funcionalidad de los Servicios;
            <br />
            (4) Alquilar, rentar, copiar, facilitar acceso o sublicenciar una parte de los Servicios o de los contenidos de RESERVA YA a terceros;
            <br />
            (5) Usar alguna parte de los Servicios o de los contenidos de RESERVA YA para proporcionar o incorporar alguna parte de los Servicios o de los contenidos de RESERVA YA en algún producto o servicio prestado a un tercero;
            <br />
            (6) Hacer ingeniería inversa, descompilar, desarmar o, en su defecto, procurar obtener el código fuente o interfaces de programas de aplicaciones (API, por sus siglas en inglés) no públicos para los Servicios, salvo que la ley aplicable lo permita expresamente (y, en ese caso, sólo mediante previo aviso a RESERVA YA);
            <br />
            (7) Modificar los Servicios o los contenidos de RESERVA YA o crear un producto derivado de cualquiera de los anteriormente descritos;
            <br />
            (8) Eliminar o cubrir los avisos sobre propiedad exclusiva o de otro tipo incluidos en los Servicios o en los contenidos de RESERVA YA;
            <br />
            (9) Usar los Servicios o los contenidos de RESERVA YA con fines ilegales; o
            <br />
            (10) Difundir públicamente información sobre el funcionamiento de los Servicios o de los contenidos de RESERVA YA, o acceder o usar los Servicios o los Contenidos de RESERVA YA para realizar análisis competitivos o evaluaciones comparativas.
            <br />
            <br />
            Las tarifas y valores mostrados en la Plataforma son referenciales.
            <br />
            <br />
            <strong>7. Sitios Web de Terceros.</strong>
            <br />
            El Restaurante reconoce que en la Plataforma se proporcionan referencias a otros sitios web, sobre los cuales libera de toda responsabilidad a RESERVA YA sobre el acceso, uso, y contenido en los mismos. Todas las conexiones que se proveen a otros sitios, tienen como único objetivo brindarles un mejor servicio. Las políticas y procedimientos que describimos aquí no se aplican a los servicios de terceros. Le sugerimos ponerse en contacto con estos sitios directamente para obtener información sobre sus términos y condiciones, políticas de privacidad, entre otros.
            <br />
            <br />
            <strong>8. Cambios en los Servicios.</strong>
            <br />
            RESERVA YA se reserva el derecho a realizar cambios discrecionales a los Servicios en cualquier momento, incluyendo sin limitarse a la suspensión, modificación, adición o remoción de Servicios en cualquier momento y la restricción en el uso y acceso a los mismos. Se notificarán los cambios en la Plataforma y/o los Servicios a través del mantenimiento del servidor, actualización de la aplicación, aviso o correo electrónico. En caso de que el Restaurante no esté de acuerdo con cualquiera de los cambios efectuados, podrá dejar de usar inmediatamente los Servicios y la cuenta. Si continúa utilizando la cuenta después que se hayan efectuado los cambios en los Servicios se considerará dicho uso como su aceptación total a los cambios efectuados.
            <br />
            <br />
            <strong>9. Cesión Contractual.</strong>
            <br />
            El Restaurante reconoce y acepta que RESERVA YA podrá ceder cualquier posición contractual o cualquiera de sus derechos u obligaciones bajo este Acuerdo, o vender sus activos a cualquier tercero sin necesidad de informarle y obtener el consentimiento previo y expreso del Restaurante.
            <br />
            <br />
            <strong>10. Limitación de Responsabilidad e Indemnidad.</strong>
            <br />
            Los Servicios proporcionados por RESERVA YA en la Plataforma se ofrecen sin ninguna clase de garantía. RESERVA YA no garantiza que los accesos a enlaces (vínculos) de terceras partes en la Plataforma estén libres de o no sean afectados con virus u otros elementos contaminantes o destructivos.
            <br />
            <br />
            Salvo en los casos de culpa inexcusable y dolo, RESERVA YA (ni ningunos de sus empleados, proveedores y/o licenciatarios) serán responsables por el lucro cesante o daño moral que pueda surgir directa o indirectamente del uso de (o no uso) de los Servicios, aun en los casos cuando RESERVA YA haya sido advertido de la posibilidad de que pueden surgir tales daños.
            <br />
            <br />
            A requerimiento previo de RESERVA YA, el Restaurante acepta defender, indemnizar y mantener indemne a RESERVA YA (incluyendo a sus empleados, proveedores y licenciatarios) de cualquier reclamación y gastos, incluyendo honorarios razonables de abogados, relacionados con cualquier violación suya de estos Términos de Uso o por su uso de cualquiera de los Servicios en la Plataforma.
            <br />
            <br />
            Los Restaurantes liberan a RESERVA YA de cualquiera y todas las Reclamaciones.
            <br />
            <br />
            Asimismo, respecto a la supresión o modificación de la información consignada para las reservas en Restaurantes que se ponen a su disposición a través de la Plataforma y de cualquier cancelación o modificación efectuada por los Restaurantes sobre sus Reservas así como por la experiencia que el Usuario tenga en estos, el Restaurante reconoce y acepta mantener a RESERVA YA indemne (incluyendo a sus directivos, socios y empleados) de cualquier reclamación y gastos, incluyendo honorarios razonables de abogados, relacionados con cualquier demanda, denuncia y en general cualquier acción de carácter legal, administrativo y/o judicial, relacionados a los Servicios brindados a través de la Plataforma o por la violación de derechos de propiedad intelectual relacionado a contenido o material publicitario proporcionado por los Restaurantes.
            <br />
            <br />
            <strong>11. Fuerza Mayor.</strong>
            <br />
            RESERVA YA no será responsable o se considerará que se encuentra en incumplimiento de cualquier retraso o falla en el rendimiento o la interrupción en la prestación de los Servicios que pueda resultar directa o indirectamente de cualquier causa o circunstancia más allá de su control razonable, incluyendo, pero sin limitarse a fallas en los equipos o las líneas de comunicación electrónica o mecánica, teléfono u otros problemas de interconexión, virus informáticos, el acceso no autorizado, robo, errores del operador, clima severo, terremotos o desastres naturales, huelgas u otros problemas laborales, guerras, o restricciones gubernamentales.
            <br />
            <br />
            <strong>12. Terminación.</strong>
            <br />
            RESERVA YA podrá suspender la capacidad de un Restaurante de usar cualquiera o todos los elementos de los Servicios, o podrá terminar este Acuerdo con validez inmediata, sin previo aviso ni explicación. Sin perjuicio de lo anterior, RESERVA YA podrá suspender el acceso a los Servicios de un Restaurante si considera que ha violado alguna parte de este Acuerdo. El Restaurante acepta que RESERVA YA no será responsable ante este por la terminación de este Acuerdo ni por los efectos de ninguna terminación de este Acuerdo. El Restaurante siempre tendrá la opción de discontinuar su uso de los Servicios en cualquier momento. El Restaurante entiende que la terminación de su cuenta en la Plataforma podrá incluir la eliminación del contenido almacenado en ella, por lo que RESERVA YA no tendrá ningún tipo de responsabilidad en absoluto sobre dicha acción, y la información eliminada.
            <br />
            <br />
            <strong>13. Ley Aplicable y Jurisdicción Aplicable.</strong>
            <br />
            Cualquier disputa que se produzca con relación a la validez, aplicación o interpretación de estos Términos de Uso, y/o cualquiera de sus acuerdos que se incluyan a este por referencia o modificación posterior, será resuelta por los Tribunales de la República Dominicana quienes tendrán jurisdicción exclusiva para conocer de los mismos. Así mismo, el Usuario reconoce que este Acuerdo se celebra, rige y se interpreta de conformidad con las leyes de la República Dominicana, y en concordancia con las siguientes legislaciones y sus modificaciones: (i) Ley General de Protección de los Derechos al Consumidor o Usuario, núm. 358-05, (ii) Ley No. 172-13 que tiene por objeto la protección integral de los datos personales asentados en archivos, registros públicos, bancos de datos u otros medios técnicos de tratamiento de datos destinados a dar informes, sean estos públicos o privados, (iii) Ley No. 310-14 que Regula el envío de correos electrónicos no solicitados, y (iv) Ley No.126-02 sobre Comercio Electrónico, Documentos y Firmas Digitales de la República Dominicana.
            <br />
            <br />
            <strong>14. Contacto.</strong>
            <br />
            Si usted tiene cualquier pregunta sobre nuestros Términos y Condiciones, por favor escribanos un email a
            {' '}
            <a href="mailto:contacto@reservaya.com">contacto@reservaya.com</a>
            .
          </Typography>
        </div>
      </Box>
    </Container>
  </>
);

export default Terms;
