/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';

import {
  Button,
  Box,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography
} from '@material-ui/core';

import DatePicker from "react-multi-date-picker"
import gregorianes from "react-date-object/locales/gregorian_es";


import { format } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
//import calendar
import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import InfoIcon from '@material-ui/icons/Info';

import { isPast } from 'date-fns';
import moment from 'moment';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,

} from '@material-ui/pickers';
import RestaurantContext from '../../layouts/Restaurant/RestaurantContext/RestaurantContext';
import DateObject from "react-date-object";

import './RestaurantOffer.scss';
import * as RestaurantsApi from '../../api/restaurants/restaurants';
import isTruthy from '../../utils/isTruthy';
import SnackbarAlert from '../SnackbarAlert';
import NumberIncrements from '../NumberIncrements';
import DialogCreateZone from '../DialogCreateZone';

const RestaurantOffer = ({ setReload, idData }) => {
  const { offersData, hasOffer } = useContext(RestaurantContext);
  const [hasOffers, setHasOffers] = useState(false);
  const [values, setValues] = useState([])


  //hasOffers
  /*  const [acceptAutomaticReservation, setAcceptAutomaticReservation] = useState(false);
  const [automaticReservationsData, setAutomaticReservationsData] = useState(); */
  const { acceptAutomaticReservation, automaticReservationsData } = useContext(RestaurantContext);
  const [changedData, setChangedData] = useState();
  const [totalCapacity, setTotalCapacity] = useState(0);
  const [isFieldActive, setIsFieldActive] = useState();
  const [isFieldExclusive, setIsFieldExclusive] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hasEdit, setHasEdit] = useState(false);
  const [reload, setLocalReload] = useState(false);
  const [createZoneDialog, setCreateZoneDialog] = useState(false);
  //repeat checkbox
  const [repeat, setRepeat] = useState(false);
  // const [values, setValues] = useState([new Date('2023-12-15'), new Date('2023-12-16'), new Date('2023-12-17')])



  //repead days, 1,2,3,4,5,6,7 (monday, tuesday, wednesday, thursday, friday, saturday, sunday) all false
  const [repeatDays, setRepeatDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  //start
  //offers
  const [offer, setOffer] = useState({});
  //automatica data initialize with capacity=1 , exclusive=false
  const [automaticData, setAutomaticData] = useState({});
  //startdate, endate
  const [validations, setValidations] = useState({
    date: true,
    time: true,
    name: true,
    numberPeople: false,
    email: true,
    numberPeople: false,
    phone: true,
    startDate: true,
    startTime: true,
    endTime: true,
    endDate: true,
    // categories: false,
    // comments: true,
  });

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [isCalendarStartDateOpen, setIsCalendarStartDateOpen] = useState(false);
  const [isCalendarEndDateOpen, setIsCalendarEndDateOpen] = useState(false);


  //fields (title , description,)
  const [fields, setFields] = useState({
    title: '',
    description: '',
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    type: 'oferta',
  });
  const handleValidations = (field, value) => {
    const mapValidations = {
      title: (title) => title.length > 0,
      description: (description) => description.length > 0,


      startDate: (startDate) => true,

      endDate: (ddate) => true,
      type: () => true,
    };
    const newValidations = {
      ...validations,
    };
    newValidations[field] = mapValidations[field](value);
    setValidations(newValidations);
  };
  const updateFields = (field, value) => {
    const newFields = {
      ...fields,
    };
    newFields[field] = value;
    setFields(newFields);
    handleValidations(field, value);
  };
  const handleDateStartDateChange = (date) => {
    setStartDate(date);
    const target = format(new Date(date), 'yyyy-MM-dd');
    updateFields('startDate', target);

    // if (dateRegex.test(date)) {
    // }    //validatedates(target, fields.startTime, fields.endDate, fields.endTime);
  };
  const handleDateEndDateChange = (date) => {
    setEndDate(date);
    // if (dateRegex.test(date)) {
    // }
    const target = format(new Date(date), 'yyyy-MM-dd');
    updateFields('endDate', target);
    //validatedates(fields.startDate, fields.startTime, target, fields.endTime);
  };
  // handletypechange
  const handleTypeChange = (event) => {
    const { value } = event.target;
    updateFields('type', value);
  };
  const closeCreateZoneDialog = () => {
    setCreateZoneDialog(false);
  };
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: 'success',
    text: 'Placeholder',
  });
  const [openTooltip, setOpenTooltip] = useState(false);


  useEffect(() => {
    if (hasOffer === 0) {
      setHasOffers(false);
    } else {
      setHasOffers(true);
    }
    if (offersData.length > 0) {
      console.log('hay offersData');


      console.log(offersData);
      setOffer(offersData[0]);
      var momentsd = moment(offersData[0].startDate);
      var momented = moment(offersData[0].endDate);
      setStartDate(momentsd);
      setEndDate(momented);
      setFields({
        title: offersData[0].title,
        description: offersData[0].description,
        startDate: offersData[0].startDate,
        endDate: offersData[0].endDate,
        type: offersData[0].type,
      });
      var dates = offersData[0].dates;
      var parsedjson = JSON.parse(dates);
      console.log("parsedjson");
      console.log(parsedjson);
      console.log("LAS FECHAS SON");
      console.log(dates);
      //set values of dates
      var datesarray = [];
      for (var i = 0; i < parsedjson.length; i++) {
        var date = new Date(parsedjson[i]);
        datesarray.push(date);
      }
      console.log("datesarray");
      console.log(datesarray);
      setValues(datesarray);

      //set values of repeat

      if (offersData[0].repeats == 1) {
        setRepeat(true);

        var days = {
          monday: offersData[0].monday == 1,
          tuesday: offersData[0].tuesday == 1,
          wednesday: offersData[0].wednesday == 1,
          thursday: offersData[0].thursday == 1,
          friday: offersData[0].friday == 1,
          saturday: offersData[0].saturday == 1,
          sunday: offersData[0].sunday == 1,
        };

        setRepeatDays(days);
        console.log("se cargaron los dias");
        console.log(days);
      }



    }

  }, [reload, idData]);

  const changeCheckbox = () => {
    setHasOffers(!hasOffers);

  };


  const saveChanges = async () => {
    var numberhasoffers = 0;
    if (hasOffers) {
      numberhasoffers = 1;
    }
    var body = {};
    body.hasOffers = numberhasoffers;
    body.title = fields.title;
    body.description = fields.description;
    body.startDate = fields.startDate;
    body.endDate = fields.endDate;
    body.type = fields.type;

    console.log('data');
    console.log(body);
    var jsondates = [];
    const jsonVariable = JSON.stringify(values);
    console.log('jsonVariable');
    console.log(jsonVariable);
    body.dates = jsonVariable;
    //prepare weekdays
    body.repeat = repeat;
    body.monday = repeatDays.monday;
    body.tuesday = repeatDays.tuesday;
    body.wednesday = repeatDays.wednesday;
    body.thursday = repeatDays.thursday;
    body.friday = repeatDays.friday;
    body.saturday = repeatDays.saturday;
    body.sunday = repeatDays.sunday;

    try {
      const { success, message } = await RestaurantsApi.editOffer(body);
      if (success) {
        setSnackbar({
          open: true,
          severity: 'success',
          text: message,
        });
        setTimeout(() => {
          setReload(true);
        }, 1000);
      } else {
        setSnackbar({
          open: true,
          severity: 'error',
          text: message,
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        severity: 'error',
        text: error,
      });
    }
  };

  const discardChanges = () => {
    setLocalReload(true);
    setHasEdit(false);
  };

  const renderView = () => (
    <>
      <div className="w-100 mx-2">
        <div className="RestaurantOffer-form-container mr-2">
          <div style={{ color: 'black', fontFamily: 'roboto', fontSize: '16px' }}>
            <span>
              <b>Oferta Disponible</b>
              <br />
            </span>
          </div>
          <div className="RestaurantOffer__form">
            <div className="RestaurantOffer__form__checkbox">
              <div>
                <FormControlLabel
                  key={uuid()}
                  label="Activar funcionalidad"
                  control={(
                    <Checkbox
                      checked={hasOffers}
                      name={'hasOffers'}
                      disableRipple
                      color="primary"
                      onChange={() => { changeCheckbox(); }}
                    />
                  )}
                />
              </div>
              <div key={uuid()} className="restaurantCapacity-area-option">
                <div className="textoContador" style={{ color: 'gray', fontFamily: 'roboto', padding: '10px' }} >
                  Esta opción ofrece la posibilidad de presentar a los usuarios de Reserva Ya la disponibilidad de ofertas en su restaurante, permitiendo que aparezcan en los filtros de búsqueda
                </div>
              </div>
            </div>
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="title"
              label="Título"
              onChange={(e) => updateFields('title', e.target.value)}
              value={fields.title}
            />
          </div>
          <div className="w100 mb-1">
            <TextField
              className="w100"
              id="description"
              label="Descripción"
              onChange={(e) => updateFields('description', e.target.value)}
              value={fields.description}
            />
          </div>
          <div className="w100 mb-1">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Tipo de experiencia
                </InputLabel>
                <NativeSelect
                  defaultValue={fields.type}
                  inputProps={{
                    name: 'type',
                    id: 'uncontrolled-native',
                  }}
                  onChange={(e) => {
                    updateFields('type', e.target.value);
                  }}
                >
                  <option value="Actividades">Actividades</option>
                  <option value="Happy hour">Happy hour</option>
                  <option value="Musica en Vivo">Música en Vivo</option>
                  <option value="Productos de temporada">Productos de temporada</option>
                  <option value="Restaurant week">Restaurant Week</option>
                  <option value="Transmisión de eventos deportivos">Transmisión de eventos deportivos</option>

                </NativeSelect>
              </FormControl>
            </Box>
          </div>
          <div>
            <div>Selecciona las fechas en la que se promocionarà la oferta</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <div className="mb-2">
                <DatePicker
                  multiple
                  value={values}
                  onChange={setValues}
                  locale={gregorianes}
                /> </div>
            </MuiPickersUtilsProvider>

          </div>
          <div className="w100 mb-1">
            <div className="RestaurantOffer__form__checkbox">
              <div>
                <FormControlLabel
                  key={uuid()}
                  label="Repetir oferta"
                  control={(
                    <Checkbox
                      checked={repeat}
                      name={'repeat'}
                      disableRipple
                      color="primary"
                      onChange={() => { setRepeat(!repeat); }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div>Selecciona el rango de fechas donde se repetira la oferta los dias que escojas</div>
          <div className="row">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <div className="w100">
                <Grid container>
                  <Grid item xs={12} md={5}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarStartDateOpen(true); }}
                      onClose={() => setIsCalendarStartDateOpen(false)}
                      open={isCalendarStartDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de inicio de la oferta"
                      invalidLabel='Fecha invalida'
                      invalidDateMessage="No es un formato válido"
                      value={startDate}
                      onChange={handleDateStartDateChange}
                      //header disables
                      disableToolbar
                      autoOk
                    >
                    </KeyboardDatePicker>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <KeyboardDatePicker
                      onClick={() => { setIsCalendarEndDateOpen(true); }}
                      onClose={() => setIsCalendarEndDateOpen(false)}
                      open={isCalendarEndDateOpen}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Fecha de finalización de la oferta"
                      value={endDate}
                      onChange={handleDateEndDateChange}
                      //header disables
                      disableToolbar
                      autoOk
                    >
                    </KeyboardDatePicker>
                  </Grid>
                </Grid>
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="weekdays-holder">
            <div className='mt-2'>
              Selecciona los dias que se repetirá la oferta
            </div>
            <div className="weekdays">
              <FormControlLabel
                key={uuid()}
                label="Lunes"
                control={(
                  <Checkbox
                    checked={repeatDays.monday}
                    name={'monday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, monday: !repeatDays.monday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Martes"
                control={(
                  <Checkbox
                    checked={repeatDays.tuesday}
                    name={'tuesday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, tuesday: !repeatDays.tuesday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Miércoles"
                control={(
                  <Checkbox
                    checked={repeatDays.wednesday}
                    name={'wednesday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, wednesday: !repeatDays.wednesday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Jueves"
                control={(
                  <Checkbox
                    checked={repeatDays.thursday}
                    name={'thursday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, thursday: !repeatDays.thursday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Viernes"
                control={(
                  <Checkbox
                    checked={repeatDays.friday}
                    name={'friday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, friday: !repeatDays.friday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Sábado"
                control={(
                  <Checkbox
                    checked={repeatDays.saturday}
                    name={'saturday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, saturday: !repeatDays.saturday }); }}
                  />
                )}
              />
              <FormControlLabel
                key={uuid()}
                label="Domingo"
                control={(
                  <Checkbox
                    checked={repeatDays.sunday}
                    name={'sunday'}
                    disableRipple
                    color="primary"
                    onChange={() => { setRepeatDays({ ...repeatDays, sunday: !repeatDays.sunday }); }}
                  />
                )}
              />
            </div>
          </div>
          <div className="RestaurantOffer__cta-container">
            <Button
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => { discardChanges(); }}
            >
              CANCELAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={() => { saveChanges(); }}

            >
              GUARDAR
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <SnackbarAlert snackbar={snackbar} setSnackBar={setSnackbar} />
      <Box
        component="div"
        className="dFlex flex-column w100 mb-5"
      >
        <Grid container>
          <Grid item xs={12} md={4} className="RestaurantOffer-left-grid">
            <div className="pr-3 ml-1">
              <Typography variant="h6">
                Oferta Disponible
              </Typography>
              <div>
                <Typography variant="body1">
                  Elige si deseas que tu restaurante aparezca en la sección de ofertas de ¡Reserva Ya!
                </Typography>
              </div>
            </div>

          </Grid>
          <Grid item xs={12} md={8} className="RestaurantOffer-right-grid">
            {renderView()}
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

RestaurantOffer.propTypes = {
  setReload: PropTypes.func,
  idData: PropTypes.string,
};

RestaurantOffer.defaultProps = {
  setReload: () => { },
  idData: '',
};

export default RestaurantOffer;
